'use client';

import type * as React from 'react';
import { DayPicker } from 'react-day-picker';
import dayjs from 'dayjs';
import { cn } from '~/utils/cn';

import 'react-day-picker/style.css?url';

export type CalendarProps = React.ComponentPropsWithRef<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row',
        month: 'space-y-4 mx-auto',
        month_caption: 'flex justify-center pt-1 relative items-center text-sm font-medium',
        caption_label: 'hidden',
        nav: 'flex items-start z-10',
        button_previous: cn(
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
          'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          'absolute left-3',
        ),
        button_next: cn(
          'border bg-red-500 border-input hover:bg-accent hover:text-accent-foreground',
          'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
          'absolute right-3',
        ),
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'text-muted-foreground rounded-md w-9 font-medium text-sm',
        week: 'flex w-full mt-2',
        day: 'h-9 w-9 text-center font-medium p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button: cn(
          'text-sm inline-flex font-medium items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
          'hover:bg-accent hover:text-accent-foreground',
          'h-9 w-9 p-0 aria-selected:opacity-100',
        ),
        range_end: 'day-range-end',
        selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        today: 'bg-accent text-accent-foreground',
        outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        disabled: 'text-muted-foreground opacity-50',
        range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        chevron: 'inline-block size-4 z-0 relative',
        ...classNames,
      }}
      startMonth={new Date(1800, 0)}
      endMonth={dayjs().add(10, 'year').toDate()}
      captionLayout="dropdown"
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
